<template>
  <div>
    <breadcrumbs :items="breadcrumbs"/>

    <waste-partner-form
        :propItem="item"
        :rvms="rvms"
        :brands="brands"
        :manufacturers="manufacturers"
        :materials="materials"
    />

  </div>
</template>

<script>
import WastePartnerForm from '@/components/waste-partners/Form'

export default ({
  name: "WastePartnerFormView",

  data: () => ({
    breadcrumbs: [
      {text: "Waste partners", disabled: false, href: "/#/waste-partners/waste-partners/all"},
      {text: "Manage", disabled: true},
    ],
  }),
  props: ['item', 'rvms', 'brands', 'manufacturers', 'materials'],
  components: {WastePartnerForm},
  mounted() {
    let action = this.item.id >= 0
        ? `Update ${this.item.company_name}`
        : 'Create'

    this.breadcrumbs.push({text: action, disabled: true})
  },
});
</script>

<style scoped>
.v-input {
  font-size: .9em;
}

.v-image {
  border: 1px dashed grey;
  height: 150px;
  width: auto;
}
</style>
