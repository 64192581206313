<template>
  <div>
    <div class="ml-6">
      <v-form ref="itemForm" v-model="validForm">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                  v-model="item.company_name"
                  :rules="[rules.required]"
                  label="Company name"
                  placeholder="Company name"
                  append-icon="mdi-domain"
                  dense
                  outlined
                  autofocus
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  v-model="item.contact_person"
                  :rules="[rules.required]"
                  label="Contact person"
                  placeholder="Contact person"
                  append-icon="mdi-account"
                  dense
                  outlined
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  v-model="item.email"
                  :rules="[rules.required, rules.email]"
                  label="Email"
                  placeholder="Email"
                  append-icon="mdi-email"
                  dense
                  outlined
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  type="number"
                  :rules="[rules.required]"
                  v-model="item.phone"
                  label="Phone number"
                  placeholder="Phone number"
                  append-icon="mdi-cellphone"
                  dense
                  outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                  type="number"
                  v-model="item.sorting_fee_percent"
                  label="Sorting fee percent"
                  placeholder="Sorting fee percent"
                  append-icon="mdi-percent"
                  dense
                  outlined
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  type="number"
                  v-model="item.variance_percent"
                  label="Variance percent"
                  placeholder="Variance percent"
                  append-icon="mdi-percent"
                  dense
                  outlined
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                  :items="yesNoOptions"
                  label="Enabled"
                  v-model="item.enabled"
                  item-text="title"
                  item-value="id"
                  dense
                  outlined
              ></v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                  :items="yesNoOptions"
                  label="Removed"
                  v-model="item.removed"
                  item-text="title"
                  item-value="id"
                  dense
                  outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-textarea
                  v-model="item.address"
                  placeholder="Address"
                  label="Address"
                  append-icon="mdi-map-marker"
                  rows="2"
                  outlined
                  dense
                  no-resize
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                  v-model="item.rvms"
                  :items="rvms"
                  :rules="[v => !!(v && v.length) || 'At least one item is required']"
                  item-text="title"
                  item-value="id"
                  label="RVMs"
                  append-icon="mdi-recycle"
                  outlined
                  clearable
                  multiple
                  chips
                  class="pt-3"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-row class="mt-1 ml-1">
        <v-col cols="12">
          <v-btn
              v-if="item.id === -1"
              color="info"
              :disabled="flagUpdatingItem"
              @click="postItem(true)"
              class="mr-2"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            Save and new
          </v-btn>

          <v-btn
              color="success"
              :disabled="flagUpdatingItem"
              @click="postItem(false)"
              class="mr-2"
          >
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>

          <v-btn
              color="warning"
              @click="$router.push('/waste-partners/waste-partners/all')"
          >
            <v-icon class="mr-2">mdi-chevron-left</v-icon>
            Go back
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar
        v-model="snackbar"
        absolute
        right
        bottom
        :color="operationResultColor"
        elevation="24"
    >
      {{ operationResultMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default ({
  name: "WastePartnerFormComponent",

  data: () => ({
    item: {},
    validForm: false,
    rules: {
      required: value => !!value || "This field is required",
      email: value => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid email format"
      }
    },
    yesNoOptions: [
      {id: 1, title: 'Yes'},
      {id: 0, title: 'No'}
    ],
    operationResultMessage: "",
    operationResultColor: "",
    snackbar: false,
    flagUpdatingItem: false,
  }),
  props: ['propItem', 'rvms', 'brands', 'manufacturers', 'materials'],
  mounted() {
    this.item = Object.assign(this.item, this.propItem)

    if (!this.item.id) {
      this.resetItem()
    }

    this.$refs.itemForm.validate()
  },
  methods: {
    resetItem() {
      this.item = {
        'id': -1,
        'enabled': 1,
        'removed': 0,
        'rvms': [],
      }
    },
    postItem(addNew) {
      if (this.$refs.itemForm.validate()) {
        this.flagUpdatingItem = true;
        let url = `${process.env.VUE_APP_BASE_API}waste-partners/waste-partners/update-or-create`;

        axios({
          method: "post",
          url: url,
          data: {
            item: this.item
          },
          headers: {
            Authorization: `Bearer ${this.$store.getters.getJwt}`
          },
        }).then((response) => {
          if (addNew) {
            this.flagUpdatingItem = false;

            this.operationResultMessage = response.data;
            this.operationResultColor = "success";
            this.snackbar = true;

            this.resetItem()
          } else {
            this.$router.push('/waste-partners/waste-partners/all')
          }
        }).catch((error) => {
          this.flagUpdatingItem = false;

          this.snackbar = true;
          this.operationResultMessage = error.data ?? "An error has occurred.";
          this.operationResultColor = "error";
        });
      }
    }
  }
});
</script>

<style scoped>
.v-input {
  font-size: .9em;
  padding-top: 5px !important;
}

</style>
